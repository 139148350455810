$( document ).on('turbolinks:load', function() {

    $('[data-toggle="buttons"] .review-attribute-btn').on('click', function () {
        // toggle style
        $(this).toggleClass('btn-primary btn-outline-primary active');

        // toggle checkbox
        var $chk = $(this).find('[type=checkbox]');
        $chk.prop('checked', !$chk.prop('checked'));

        return false;
    });

    $('.rating-radio').change(function () {
        if ($(this).is(':checked')) {
            let contractorId = $(this).data('contractor-id');

            if (parseInt($(this).val()) > 3) {
                $('#attributes_for_good_experience_' + contractorId).show();
                $('#attributes_for_bad_experience_' + contractorId).hide();
            }
            else if (parseInt($(this).val()) === 3) {
                $('#attributes_for_good_experience_' + contractorId).show();
                $('#attributes_for_bad_experience_' + contractorId).show();
            } else if (parseInt($(this).val()) < 3) {
                $('#attributes_for_good_experience_' + contractorId).hide();
                $('#attributes_for_bad_experience_' + contractorId).show();
            } else {
                $('#attributes_for_good_experience_' + contractorId).hide();
                $('#attributes_for_bad_experience_' + contractorId).hide();
            }
        }
    });

    const submitReviewForm = $(".submit_review_form");

    submitReviewForm.on("ajax:success", function (event){
        $(this).find(".submit-status").addClass('text-success');
        $(this).find(".submit-status").removeClass('text-danger');
        $(this).find(".submit-status").html("Saved!");

        $(this).attr('action', "/contractor_reviews/" + event.detail[0].id);
        $(this).append('<input type="hidden" name="_method" value="put">');
    });
    submitReviewForm.on("ajax:error", function () {
        $(this).find(".submit-status").addClass('text-danger');
        $(this).find(".submit-status").removeClass('text-success');
        $(this).find(".submit-status").html("Error");
    });
});
